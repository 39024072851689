import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import "./App.css";
//Components
import Form from "./components/Form";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NW7669V' });
  }, []);

  return (
    <div className="App">
      <Form />
    </div>
  );
}

export default App;
