// You can make any answer not applicable by setting points to -1. this will cause the total score to ignore that question for that category.
const questions = [
  {
    category: `Security`,
    maxPoints: 9.5,
    questions: [
      {
        title: `Organizational Structure and Information Security Management`,
        hint: `Think about how top management is involved, who reports to whom about security, and what specific programs you have to protect your information from unauthorized access. Consider how this works whether people are working from home, in the office, or both.`,
        question: `How would you describe your company's organizational structure and information security management?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have a comprehensive structure with leadership oversight and a dedicated security program applicable to all work environments`,
            points: 2,
          },
          {
            text: `We have some structure and security measures, but they're not fully developed or consistently applied`,
            points: 1,
          },
          {
            text: `We have a basic organizational structure but limited formal security governance`,
            points: 0.5,
          },
          { text: `We don't have specific governance structures`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Access Control and Authentication`,
        hint: `Think about how you decide who can use important business systems or see sensitive information, and how you check it's really them, no matter where they're working from.`,
        question: `How do you control who can access your critical systems and data?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We use role-based access control and multi-factor authentication for all critical systems across all work environments`,
            points: 2,
          },
          {
            text: `We have basic access controls in place but lack multi-factor authentication or consistency across environments`,
            points: 1,
          },
          { text: `We have minimal access controls`, points: 0.5 },
          { text: `We don't have specific access controls`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Network and System Security Measures`,
        hint: `Select all that apply`,
        question: `Which security measures do you have in place to protect your network and systems?`,
        maxPoints: 3.5,
        multiple: true,
        options: [
          { text: `Firewalls or cloud-based security solutions`, points: 0.7 },
          { text: `Encryption for data transmission and storage`, points: 0.7 },
          {
            text: `Anti-virus and anti-malware software on all endpoints`,
            points: 0.7,
          },
          {
            text: `Intrusion detection/prevention systems or monitoring services`,
            points: 0.7,
          },
          {
            text: `Network and application traffic logging and monitoring`,
            points: 0.7,
          },
          {
            text: `Regular vulnerability assessments and penetration testing`,
            points: 0.7,
          },
          {
            text: `Secure configurations for all devices used in remote or on-site work`,
            points: 0.7,
          },
          { text: `None of the above`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Incident Response and Management`,
        hint: `This includes having a response plan for cyber attacks, data breaches, or other security incidents, applicable to all operational models.`,
        question: `How would you describe your company's approach to handling security incidents and vulnerabilities?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have a formal incident response plan, conduct regular testing (e.g., drills, simulations), and promptly address vulnerabilities`,
            points: 2,
          },
          {
            text: `We have basic incident procedures and occasionally test our response capabilities`,
            points: 1,
          },
          {
            text: `We handle incidents as they occur but don't have formal plans or regular testing`,
            points: 0.5,
          },
          {
            text: `We don't have specific procedures for security incidents`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
    ],
  },
  {
    category: `Availability`,
    maxPoints: 4,
    questions: [
      {
        title: `System Availability and Performance Monitoring`,
        hint: `Consider system uptime monitoring, performance metrics, capacity planning, and whether these practices apply in remote and hybrid environments.`,
        question: `How does your company ensure that your systems and services are available for operation and use?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have robust monitoring systems, performance metrics, and capacity planning to ensure high availability across all operational models`,
            points: 2,
          },
          {
            text: `We have basic monitoring and occasionally review performance metrics`,
            points: 1,
          },
          {
            text: `We rely on ad-hoc monitoring without formal processes`,
            points: 0.5,
          },
          {
            text: `We don't have specific availability management practices`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Disaster Recovery and Business Continuity`,
        hint: `Consider disaster recovery plans, business continuity plans, and regular testing of these plans, including for remote operations.`,
        question: `What plans do you have in place to ensure availability in the event of a disruption?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have formal disaster recovery and business continuity plans that are regularly tested and updated`,
            points: 2,
          },
          {
            text: `We have plans in place but rarely test or update them`,
            points: 1,
          },
          {
            text: `We have informal plans but nothing documented`,
            points: 0.5,
          },
          {
            text: `We don't have disaster recovery or business continuity plans`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
    ],
  },
  {
    category: `Processing Integrity`,
    maxPoints: 4,
    questions: [
      {
        title: `Change Management and Operational Updates`,
        hint: `This includes changes to business processes, service offerings, software applications (if applicable), and other critical components.`,
        question: `How does your company manage changes to its operational processes, services, or systems to ensure integrity?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have a formal change management policy with documented approval processes, testing procedures, and rollback plans for all significant changes`,
            points: 2,
          },
          {
            text: `We have basic change management procedures, but they're not comprehensive or consistently followed`,
            points: 1,
          },
          {
            text: `We implement changes as needed without formal processes or documentation`,
            points: 0.5,
          },
          { text: `We don't have change management processes`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Data Processing Accuracy and Validation`,
        hint: `Think about how you check data for accuracy, handle mistakes and reconcile it, regardless if it is processed manually or electronically`,
        question: `How do you ensure that data processing (e.g., transactions, records) is complete, accurate, timely, and authorized?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have automated or well-defined manual data validation, error detection, and reconciliation processes`,
            points: 2,
          },
          {
            text: `We have basic checks for data processing accuracy`,
            points: 1,
          },
          {
            text: `We rely on users or clients to report processing errors`,
            points: 0.5,
          },
          {
            text: `We don't have specific data processing controls`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
    ],
  },
  {
    category: `Confidentiality`,
    maxPoints: 4,
    questions: [
      {
        title: `Data Classification and Handling`,
        hint: `Think about how you label different types of information, what rules you have for using it, and how you teach employees to protect it, whether they're in the office or working remotely.`,
        question: `How does your company classify and handle confidential information?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have a formal data classification scheme, handling procedures, and regular training for staff in all operational settings`,
            points: 2,
          },
          {
            text: `We have informal data classification and basic handling procedures`,
            points: 1,
          },
          {
            text: `We recognize confidential data but lack formal procedures`,
            points: 0.5,
          },
          {
            text: `We don't have data classification or handling procedures`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Encryption and Protection of Confidential Data`,
        hint: `Are you encrypting sensitive data so others can't read it?  How do you control who can see sensitive data and how do you prevent it from being lost?  Do you have measures in place for people who are working from home?`,
        question: `What measures are in place to protect confidential data both at rest and in transit?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We use strong encryption for data at rest and in transit, with robust access controls and data loss prevention strategies`,
            points: 2,
          },
          {
            text: `We encrypt data in transit but not at rest, with basic access controls`,
            points: 1,
          },
          {
            text: `We have minimal encryption and access controls`,
            points: 0.5,
          },
          {
            text: `We don't have specific measures to protect confidential data`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
    ],
  },
  {
    category: `Privacy`,
    maxPoints: 4,
    questions: [
      {
        title: `Privacy Policies and Notice`,
        hint: `Think about rules for collecting, using, keeping, sharing, and getting rid of personal information.  This applies no matter what kind of business you are or how you operate.`,
        question: `Does your company have documented privacy policies?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `Yes, we have comprehensive privacy policies that are communicated to stakeholders`,
            points: 2,
          },
          {
            text: `We have basic privacy policies but they are not comprehensive or well-communicated`,
            points: 1,
          },
          {
            text: `We have informal privacy practices but no documented policies`,
            points: 0.5,
          },
          { text: `We don't have privacy policies`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Consent and Data Subject Rights`,
        hint: `Think about how you ask people if it's okay to use their information, and how you let them see, fix, or remove their personal details if they ask.  For example, if a visitor to your website submits a form, do you give them options to manage the information you store for them?`,
        question: `How does your company ask for permission to use personal information and handle people's requests about their data?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We have formal processes to ask for permission and handle people's requests about their data, in compliance with applicable laws`,
            points: 2,
          },
          {
            text: `We have basic processes but they are not consistently applied`,
            points: 1,
          },
          { text: `We handle requests as they occur`, points: 0.5 },
          {
            text: `We don't have processes for consent or data subject rights`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
    ],
  },
  {
    category: `Additional Areas`,
    maxPoints: 8,
    questions: [
      {
        title: `Asset Management`,
        hint: `This includes tracking company-owned devices, software licenses, and sensitive data assets.`,
        question: `How does your company manage its assets (hardware, software, and data), considering both remote and on-site environments?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `We use automated tools to track all hardware, software, and data assets across all work environments`,
            points: 2,
          },
          { text: `We maintain and regularly update inventory`, points: 1 },
          {
            text: `We have basic lists but they are not comprehensive or regularly updated`,
            points: 0.5,
          },
          {
            text: `We don't have formal asset management practices`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Vendor and Service Provider Management`,
        hint: `Vendor practices can directly impact your security and compliance.`,
        question: `Do you assess and monitor the security and privacy practices of your third-party vendors and service providers—including cloud and remote services?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `Yes, we perform regular assessments and require compliance with our standards`,
            points: 2,
          },
          {
            text: `We assess vendors during onboarding but not regularly thereafter`,
            points: 1,
          },
          {
            text: `We rely on vendor assurances without formal assessments`,
            points: 0.5,
          },
          {
            text: `Not applicable - We don't assess vendor practices`,
            points: 0,
          },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Risk Management`,
        hint: `This includes identifying, assessing, mitigating, and monitoring risks related to the Trust Services Criteria - security, availability, processing integrity, confidentiality, and privacy.`,
        question: `Do you have a formal risk management process suitable for your operational model that addresses risks across all TSC categories?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `Yes, we have a comprehensive risk management process reviewed regularly`,
            points: 2,
          },
          {
            text: `We have a basic risk management process but it's not comprehensive or regularly reviewed`,
            points: 1,
          },
          {
            text: `We handle risks as they arise without a formal process`,
            points: 0.5,
          },
          { text: `We don't have a risk management process`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
      {
        title: `Employee Training and Awareness`,
        hint: `Consider training frequency, coverage of topics, and inclusion of all employees and contractors, whether remote or on-site.`,
        question: `Do you provide regular training to your workforce on security, confidentiality, and privacy practices, regardless of the work environment?`,
        maxPoints: 2,
        multiple: false,
        options: [
          {
            text: `Yes, we provide comprehensive training at least annually to all relevant personnel`,
            points: 2,
          },
          {
            text: `We provide training but it's not regular or doesn't cover all necessary topics`,
            points: 1,
          },
          {
            text: `We provide minimal training without formal programs`,
            points: 0.5,
          },
          { text: `We don't provide specific training`, points: 0 },
          { text: `Not sure`, points: 0 },
        ],
      },
    ],
  },
];

export default questions;
