import React, { useState, useEffect } from "react";
import questions from "../data/questions";
import axiosInstance from "../api/axios";
import unsupportedEmailDomains from "../data/unsupportedEmails";
import { PropagateLoader } from "react-spinners";
import TagManager from "react-gtm-module"; // Add this import at the top
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const MultiStepForm = () => {
  const [maxPoints, setMaxPoints] = useState(0);
  const [currentSection, setCurrentSection] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [formData, setFormData] = useState({
    businessEmail: "",
    firstName: "",
    lastName: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //Clear the error message after 3 seconds
  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [error]);
  //Calculate the points for each question
  useEffect(() => {
    const calculatePoints = () => {
      let points = 0;
      questions.forEach((category) => {
        points += category.maxPoints;
      });
      return points;
    };
    setMaxPoints(calculatePoints());
  }, []);
  const handleAnswerChange = (optionIndex, categoryIndex, questionIndex) => {
    setAnswers((prevAnswers) => {
      const category = questions[categoryIndex].category;
      const questionTitle =
        questions[categoryIndex].questions[questionIndex].title;
      const questionText =
        questions[categoryIndex].questions[questionIndex].question;
      const selectedOption =
        questions[categoryIndex].questions[questionIndex].options[optionIndex];
      const isMultiple =
        questions[categoryIndex].questions[questionIndex].multiple;
      const maxQuestionPoints =
        questions[categoryIndex].questions[questionIndex].maxPoints;

      let updatedCategoryAnswers = {
        ...prevAnswers[category],
        answers: {
          ...prevAnswers[category]?.answers,
          [questionTitle]: {
            answers: {
              [questionText]: [
                ...(prevAnswers[category]?.answers?.[questionTitle]?.answers?.[
                  questionText
                ] || []),
              ],
            },
            maxPoints: maxQuestionPoints,
            pointsScored: 0,
          },
        },
        maxPoints: questions[categoryIndex].maxPoints,
        pointsScored: 0,
      };

      if (isMultiple) {
        const existingAnswers = updatedCategoryAnswers.answers[questionTitle].answers[questionText];
        const existingIndex = existingAnswers.findIndex(
          (item) => item.text === selectedOption.text
        );
        
        if (existingIndex > -1) {
          // Remove the answer if it's already selected
          existingAnswers.splice(existingIndex, 1);
        } else if (existingAnswers.length < 5) {
          // Add the answer only if there are fewer than 5 selections
          existingAnswers.push({
            text: selectedOption.text,
            points: selectedOption.points,
          });
        }
      } else {
        updatedCategoryAnswers.answers[questionTitle].answers[questionText] = [
          {
            text: selectedOption.text,
            points: selectedOption.points,
          },
        ];
      }

      // Calculate pointsScored for the question
      updatedCategoryAnswers.answers[questionTitle].pointsScored =
        updatedCategoryAnswers.answers[questionTitle].answers[
          questionText
        ].reduce((sum, answer) => sum + answer.points, 0);

      // Calculate category pointsScored
      updatedCategoryAnswers.pointsScored = Object.values(
        updatedCategoryAnswers.answers
      ).reduce((sum, question) => sum + question.pointsScored, 0);

      return {
        ...prevAnswers,
        [category]: updatedCategoryAnswers,
      };
    });

    // Automatically move to next question only for single-answer questions
    if (!questions[categoryIndex].questions[questionIndex].multiple) {
      handleNext();
    }
  };

  const handleNext = () => {
    if (currentSection === -1) {
      const { firstName, lastName, businessEmail } = formData;
      if (!firstName || !lastName || !businessEmail) {
        setError("Please fill in all required fields.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(businessEmail)) {
        setError("Please enter a valid email address.");
        return;
      }

      const emailDomain = businessEmail.substring(
        businessEmail.lastIndexOf("@")
      );
      if (unsupportedEmailDomains.includes(emailDomain)) {
        setError("The email domain is not supported.");
        return;
      }

      setCurrentSection(0); // Move to the first section (Security)
    } else {
      const categoryQuestions = questions[currentSection].questions;
      if (currentQuestion < categoryQuestions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else if (currentSection < questions.length - 1) {
        setCurrentSection(currentSection + 1);
        setCurrentQuestion(0);
      }
    }
  };

  const handleBack = () => {
    if (currentSection === 0 && currentQuestion === 0) {
      setCurrentSection(-1); // Go back to personal info
    } else if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      setCurrentQuestion(questions[currentSection - 1].questions.length - 1);
    }
  };

  const handleSubmit = () => {
    console.log({ ...formData, answers, maxPoints });

    setLoading(true);
    axiosInstance("/submit", {
      method: "POST",
      data: { ...formData, answers, maxPoints },
    })
      .then((res) => {

        const dataLayerObject = {
          event: "soc2_quiz_submission",
          formName: "SOC2 Readiness Quiz",
          submissionEmail: formData.businessEmail,
          // Add any other relevant data you want to track
        };
  
        TagManager.dataLayer({
          dataLayer: dataLayerObject,
        });
   
      })
      .catch((error) => {
        console.error("Error submitting the form:", error);
        setError("An error occurred while submitting the form.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const currentCategoryData = questions[currentSection] || {};
  const currentQuestionData =
    currentCategoryData.questions?.[currentQuestion] || {};

  const isQuestionAnswered = (category, questionTitle, questionText) => {
    const isAnswered =
      answers[category]?.answers?.[questionTitle]?.answers?.[questionText]
        ?.length > 0;

    return isAnswered;
  };

  const isLastCategory = currentSection === questions.length - 1;
  const isLastQuestion =
    currentQuestion === currentCategoryData.questions?.length - 1;

  return (
    <div className="flex justify-center items-center min-h-screen">
      {currentSection === -1 ? (
        <div className="relative mb-4 w-full max-w-2xl">
          <p className="absolute top-[-20px] text-red-500">{error}</p>
          <div className="mb-2">
            <label htmlFor="businessEmail">Business Email</label>
            <input
              type="email"
              id="businessEmail"
              name="businessEmail"
              value={formData.businessEmail}
              onChange={handleInputChange}
              className="p-2 w-full rounded border border-gray-300"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className="p-2 w-full rounded border border-gray-300"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className="p-2 w-full rounded border border-gray-300"
            />
          </div>
          <div className="flex justify-between mt-4">
            <button onClick={handleBack} className="!p-4">
              {" "}
              <FaAngleLeft />
            </button>
            <button
              onClick={handleNext}
              disabled={
                !formData.businessEmail ||
                !formData.firstName ||
                !formData.lastName
              }
              className="!p-4"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-[100vh] w-full">
          <div className="flex flex-col flex-1 gap-2 justify-center">
            <div>
              <h2>{currentCategoryData.category}</h2>
              <h3 className="text-xl font-semibold">
                {currentQuestionData.title}
              </h3>
            </div>
            <div>
              <p>{currentQuestionData.question}</p>
              <p className="text-sm text-gray-600">
                {currentQuestionData.hint}
              </p>
            </div>
          </div>

          <div className="flex flex-col flex-1 gap-3 justify-start">
            {currentQuestionData.options?.map((option, j) => {
              const category = currentCategoryData.category;
              const questionTitle = currentQuestionData.title;
              const questionText = currentQuestionData.question;
              const isSelected = answers[category]?.answers[
                questionTitle
              ]?.answers[questionText].some(
                (answer) => answer.text === option.text
              );
              return (
                <div
                  key={j}
                  className={`input cursor-pointer p-2 ${
                    isSelected ? "text-white bg-gray-600" : ""}`}
                  onClick={() =>
                    handleAnswerChange(j, currentSection, currentQuestion)
                  }
                >
                  <label
                    htmlFor={`${category}-${questionTitle}-${questionText}-${j}`}
                  >
                    {option.text}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="flex flex-1 justify-between items-center">
            {loading ? (
              <PropagateLoader className="mx-auto" />
            ) : (
              <>
                <button
                  onClick={handleBack}
                  disabled={currentSection === -1 && currentQuestion === 0}
                  className="!p-4"
                >
                  <FaAngleLeft />
                </button>
                {isLastCategory && isLastQuestion ? (
                  <button
                    onClick={handleSubmit}
                    disabled={
                      !isQuestionAnswered(
                        currentCategoryData.category,
                        currentQuestionData.title,
                        currentQuestionData.question
                      )
                    }
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    onClick={handleNext}
                    disabled={
                      !isQuestionAnswered(
                        currentCategoryData.category,
                        currentQuestionData.title,
                        currentQuestionData.question
                      )
                    }
                    className="!p-4"
                  >
                    <FaAngleRight />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
